/*eslint-env es6*/
/*global require*/
const menu = document.querySelector('.menu');
const btn = menu.querySelector('.nav-toggle');
const mstart = menu.querySelector('.mstart');
const mfocus = menu.querySelector('.mfocus');
const mteam = menu.querySelector('.mteam');
const mclients = menu.querySelector('.mclients');
const mcontact = menu.querySelector('.mcontact');
const pwrapper = document.querySelector('.page-wrapper');
btn.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})
mstart.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})
mfocus.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})
mteam.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})
mcontact.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})
mclients.addEventListener('click', evt => {
	menu.classList.toggle('active'),
	pwrapper.classList.toggle('versteckt');
})